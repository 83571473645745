<template>
  <!-- 左侧详情 -->
  <div>
    <div class="crumbs"><a href="/">首页</a> > 投诉详情</div>
    <!-- 投诉列表 -->
    <div class="details_list">
      <div class="details_list_top">
        <div class="details_list_top_title">
          {{ details_list.statement_title }}
        </div>
        <div class="Related_complaints" @click="Related_complaints(details_list.complaint_business_id)">相关投诉 ></div>
      </div>
      <div class="details_list_content">
        <div class="details_list_content_left">
          <div class="details_list_content_left_ct" style="margin-top: 10px">
            <span style="color: #1f72d9; margin-right: 3px">[诉案编号]</span>
            {{ details_list.complaint_number }}
          </div>
          <div class="details_list_content_left_ct" >
            <span style="color: #1f72d9; margin-right: 8px" >[投诉对象]</span>
            <span @click="Go_HotBrands(details_list_name.id)" style="cursor: pointer;color:rgb(254, 144, 37)">{{ details_list_name.name}}</span>
          </div>
          <div class="details_list_content_left_ct">
            <span style="color: #1f72d9; margin-right: 3px">[投诉缘由]</span>
            {{ details_list.complaint_reason }}
          </div>
          <div class="details_list_content_left_ct">
            <span style="color: #1f72d9; margin-right: 3px">[投诉诉求]</span>
            {{ details_list.complaint_appeal }}
          </div>
          <div class="details_list_content_left_ct">
            <span style="color: #1f72d9; margin-right: 3px">[涉诉金额]</span>
            {{ details_list.complaint_price }}元
          </div>
        </div>
        <div class="details_list_content_right">
          <img v-if="details_list.status == 2" src="@/assets/img/yiwancheng.png" alt="" />
          <img v-if="details_list.status == 5" src="@/assets/img/weitongguo.png" alt="" />
          <img v-if="details_list.status == 4" src="@/assets/img/yichexiao.png" alt="" />
          <img v-if="details_list.status == 0" src="@/assets/img/shenhezhong.png" alt="" />
          <img v-if="details_list.status == 1" src="@/assets/img/chulizhong.png" alt="" />
        </div>
      </div>
      <div class="details_list_bottom">
        <div class="details_list_bottom_box">
          <img v-if="collection" @click="toCollect" :src="isgive_ups == 0?z_img:z_img1" alt="" />
          <img v-else @click="toCollect" :src="isgive_ups == 1?z_img:z_img1" alt="" />
          <!-- <img @click="toCollect" :src="isgive_ups == 1?'@/assets/img/dianzan_icon1.png':'@/assets/img/dianzan_icon.png'" alt="" /> -->
          <div>{{details_list.like_num}}</div>
        </div>
        <div class="details_list_bottom_box">
          <img src="../assets/img/pinglun_icon.png" alt="" />
          <div>{{details_list.comment_num}}</div>
        </div>
        <div class="details_list_bottom_box">
          <img v-if="collection1" @click="toCollect1" :src="isCollections == 0?c_img:c_img1" alt="" />
          <img v-else @click="toCollect1" :src="isCollections == 1?c_img:c_img1" alt="" />
          <div>{{details_list.collection_num}}</div>
        </div>
        <div class="fenxiang">
          <el-dropdown @command="handleCommand">
            <span class="el-dropdown-link">
              <img class="el-icon-arrow-down el-icon--right" src="@/assets/img/fenxiang.png" alt="" />
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="a" style='background: url(https://n.sinaimg.cn/finance/blackcat/pc/img/share_QQzone_icon.png) no-repeat 10px 10px;text-align:right'><span style="margin-left:15px;">QQ空间</span></el-dropdown-item>
              <el-dropdown-item command="b" style='background: url(https://n.sinaimg.cn/finance/blackcat/pc/img/share_QQ_icon.png) no-repeat 10px 10px;text-align:right'>QQ</el-dropdown-item>
              <el-dropdown-item command="c" style='background: url(//n.sinaimg.cn/finance/blackcat/pc/img/share_weibo_icon.png) no-repeat 10px 10px;text-align:right'>微博</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <!-- 投诉详情 -->
    <div class="Complaint_details">
      <div class="Complaint_details_title">投诉详情</div>
      <div class="Complaint_details_content">
        {{details_list.complaint_details}}
      </div>
    </div>
    <!-- 投诉进度 -->
    <div class="Complaint_progress">
      <div class="Complaint_progress_title">投诉进度</div>
    </div>
    <div class="Complaint_progress_bottom">
      <div class="Complaint_progress_bottom_box"  v-if="C_Progress.uid==user_uid">
        <div class="box_yuan"></div>
        <div class="Complaint_progress_bottom_box_opinion">
          <div style="color: #999999;margin-right:20px;">您是否同意商家的处理意见？</div>
          <el-radio-group v-model="radio">
            <el-radio label="1">认可</el-radio>
            <el-radio label="2">不认可</el-radio>
          </el-radio-group>
        </div>
        <input type="text" class="opinion_send_ipt" v-model="opinion_ipt">
        <div class="opinion_send_box">
          <el-radio-group v-model="radio1">
            <el-radio label="0">公开</el-radio>
            <el-radio label="1">不公开</el-radio>
          </el-radio-group>
          <div class="opinion_send" @click="opinion_send">发送</div>
        </div>
      </div>
      <div class="Complaint_progress_bottom_box" v-if="C_Progress.evaluate_time!=0">
        <div class="box_yuan"></div>
        <div class="Complaint_progress_bottom_box_data">
          {{C_Progress.evaluate_time}}
        </div>
        <div class="Complaint_progress_bottom_box_name">[{{C_Progress.nickname}}] <span style="color:#FE9025;">评价</span>
        </div>
        <div class="Complaint_progress_bottom_box_xing">对商家处理效率和结果是否满意？
          <img v-if="C_Progress.efficiency_results_level==1" src="@/assets/img/1xing.png" alt="">
          <img v-if="C_Progress.efficiency_results_level==2" src="@/assets/img/2xing.png" alt="">
          <img v-if="C_Progress.efficiency_results_level==3" src="@/assets/img/3xing.png" alt="">
          <img v-if="C_Progress.efficiency_results_level==4" src="@/assets/img/4xing.png" alt="">
          <img v-if="C_Progress.efficiency_results_level==5" src="@/assets/img/5xing.png" alt="">
        </div>
        <div class="Complaint_progress_bottom_box_xing">对商家处理效率和结果是否满意？
          <img v-if="C_Progress.service_level==1" src="@/assets/img/1xing.png" alt="">
          <img v-if="C_Progress.service_level==2" src="@/assets/img/2xing.png" alt="">
          <img v-if="C_Progress.service_level==3" src="@/assets/img/3xing.png" alt="">
          <img v-if="C_Progress.service_level==4" src="@/assets/img/4xing.png" alt="">
          <img v-if="C_Progress.service_level==5" src="@/assets/img/5xing.png" alt="">
        </div>
        <div class="Complaint_progress_bottom_box_title">{{C_Progress.opinion_info}}</div>
      </div>
      <div class="Complaint_progress_bottom_box" v-if="C_Progress.complete_time!=0">
        <div class="box_yuan"></div>
        <div class="Complaint_progress_bottom_box_data">
          {{C_Progress.complete_time}}
        </div>
        <div class="Complaint_progress_bottom_box_name">[{{C_Progress.nickname}}] <span
            style="color:#FE9025;">确认完成</span></div>
      </div>
      <div class="Complaint_progress_bottom_box" v-for="(item,index) in A_Progress" :key="index">
        <div class="box_yuan"></div>
        <div class="Complaint_progress_bottom_box_data">
          {{item.create_time}}
        </div>
        <div class="Complaint_progress_bottom_box_name" v-if="item.uid !=null">[{{item.nickname}}] <span
            style="color:#FE9025;">回复</span></div>
        <div class="Complaint_progress_bottom_box_name" v-if="item.business_uid !=null">[{{item.business_nickname}}客服]
          <span style="color:#FE9025;">回复</span></div>
        <div class="Complaint_progress_bottom_box_name" v-if="item.is_open ==0">{{item.content}}</div>
        <div class="Complaint_progress_bottom_box_name" v-else>该内容不公开显示</div>
      </div>
      <!-- <div class="Complaint_progress_bottom_box" v-for="(items,indexs) in C_Progress.publish" :key="indexs" v-show="items.business_uid !=null">
        <div class="box_yuan"></div>
        <div class="Complaint_progress_bottom_box_data">
          {{items.create_time}}
        </div>
        <div class="Complaint_progress_bottom_box_name">[{{items.business_nickname}}客服] <span style="color:#FE9025;">回复</span></div>
        <div class="Complaint_progress_bottom_box_name">{{items.content}}</div>
      </div> -->
      <div class="Complaint_progress_bottom_box" v-if="C_Progress.adopt_time!=0">
        <div class="box_yuan"></div>
        <div class="Complaint_progress_bottom_box_data">
          {{C_Progress.adopt_time}}
        </div>
        <div class="Complaint_progress_bottom_box_name">已分配商家[{{ details_list_name.name}}] <span
            style="color:#FE9025;">商家处理中</span></div>
      </div>
      <div class="Complaint_progress_bottom_box">
        <div class="box_yuan"></div>
        <div class="Complaint_progress_bottom_box_data">
          {{C_Progress.create_time}}
        </div>
        <div class="Complaint_progress_bottom_box_name">鹰眼投诉平台<span style="color:#FE9025;">审核中</span></div>
      </div>
      <div class="Complaint_progress_bottom_box">
        <div class="box_yuan"></div>
        <div class="Complaint_progress_bottom_box_data">
          {{C_Progress.create_time}}
        </div>
        <div class="Complaint_progress_bottom_box_name">{{C_Progress.nickname}}<span>发起投诉</span></div>
      </div>
    </div>
    <!-- 评论 -->
    <div class="comment">
      <input type="text" class="comment_ipt" v-model="msg" @input="descInput" maxlength="200" placeholder="说点什么..." />
      <div class="Reply_box">
        <div class="Enter_the_number">{{ remnant }}/200</div>
        <div v-if="msg.length == 0" class="Reply">回复</div>
        <div class="Reply1" @click="Reply" v-else>回复</div>
      </div>
    </div>
  
    <!-- 最新评论 -->
    <div class="Latest_comments">
      <div class="Latest_comments_title">最新评论</div>
      <!-- <div v-if="tokens ==null" class="login_zs" @click="G_login">登录后查看</div> -->
      <div class="Latest_comments_b" v-for="(item,index) in comment_lists" :key="index">
        <div class="Latest_comments_list">
          <div class="Latest_comments_list_left">
            <img :src="item.headimgurl" alt="" />
          </div>
          <div class="Latest_comments_list_content">
            <div class="Latest_comments_list_content_top">
              <div class="names">{{item.userinfo}}</div>
              <div class="datas">{{item.add_time}}</div>
            </div>
            <div class="Latest_comments_list_content_bottom">
              {{item.content}}
            </div>
          </div>
          <div class="Latest_comments_list_right">
            <img src="../assets/img/pinglun_icon.png" @click="Eject_Comments(index,item.id,item.userinfo)" alt="" />
            <!-- <img src="../assets/img/dianzan_icon.png" @click="Eject_Commentss(index,item.id,item.userinfo)" alt="" /> -->
            <img  v-if="item.is_like == 0" @click="Eject_Commentss(item.is_like,item.id)" :src="z_img" alt="" />
          <img v-if="item.is_like == 1" @click="Eject_Commentss(item.is_like,item.id)" :src="z_img1" alt="" />
          <img v-if="item.is_like != 1 &&item.is_like != 0" @click="Go_Login()" :src="z_img" alt="" />
            <div>{{item.like_num}}</div>
          </div>
        </div>
        <div v-show="hf_cm == index" class="hf_comment">
          <input type="text" class="hf_comment_ipt" v-model="msgs" @input="descInputs" maxlength="200"
            :placeholder="'回复:'+item.userinfo" />
          <div class="hf_Reply_box">
            <div class="hf_Enter_the_number">{{ remnants }}/200</div>
            <div v-if="msgs.length == 0" class="hf_Reply">回复</div>
            <div class="hf_Reply1" v-else @click="Hf_Comments(item.id)">回复</div>
          </div>
        </div>
        <!-- 二级 -->
        <div v-for="(item,indexs) in item.childrenList" :key="indexs">
          <div class="Latest_comments_list two_Latest_comments" style="width:750px;">
            <div class="Latest_comments_list_left">
              <img :src="item.headimgurl" alt="" />
            </div>
            <div class="Latest_comments_list_content">
              <div class="Latest_comments_list_content_top">
                <div class="names">{{item.userinfo}}</div>
                <div class="datas">{{item.add_time}}</div>
              </div>
              <div class="Latest_comments_list_content_bottom">
                {{item.content}}
              </div>
            </div>
            <div class="Latest_comments_list_right">
              <!-- <img src="../assets/img/pinglun_icon.png" @click="Eject_Comments1(indexs,item.id,item.userinfo,index)" alt="" /> -->
              <!-- <img src="../assets/img/dianzan_icon.png" alt="" /> -->
              <!-- <div>{{item.like_num}}</div> -->
            </div>
          </div>
          <!-- <div v-show="hf_cm1 == indexs" class="hf_comment">
            <input type="text" class="hf_comment_ipt" v-model="msgs1" @input="descInputs" maxlength="200"
              :placeholder="'回复:'+item.userinfo" />
            <div class="hf_Reply_box">
              <div class="hf_Enter_the_number">{{ remnants1 }}/200</div>
              <div v-if="msgs1.length == 0" class="hf_Reply">回复</div>
              <div class="hf_Reply1" v-else @click="Hf_Comments1(item.id)">回复</div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <div v-if="load==true&&comment_lists.length>7" style="text-align:center;">加载中...</div>
    <div v-if="load==false&&comment_lists==''" style="text-align:center;"></div>
  </div>
</template>

<script>
import {
  complaint_details,
  comment_list,
  instantly_message,
  give_up,
  give_ups,
  Collection,
  Progress,
  Progress_reply,
  news_save,
  userComment_like,
  userCommentLike_status,
} from "../api/api";
import Axios from "axios";
import md5 from 'js-md5';
export default {
     metaInfo(){
     return{
        title: this.details_list.statement_title==undefined?'详情': this.details_list.statement_title,
        meta: [
            {
                name: 'keywords',
                content: this.details_list.complaint_reason
            },
            {
                name: 'description',
                content:this.details_list.complaint_details
            }
        ]
     }     
    },
  inject: ["reload"],
  name: "DetailsLeft",
  data() {
    return {
      tokens: "",
      user_uid: "", //登录带的uid
      Xq_Id: "", //上个页面传过来的id
      msg: "",
      msgs: "", //回评论复的输入框
      msgs1: "",   //二级回复评论的输入框
      remnant: 0,
      remnants: 0,
      remnants1: 0,
      hf_cm: -1,
      hf_cms:-1,
      hf_cm1:-1,
      details_list: "",
      details_list_name: "",
      comment_lists: [], //评论列表展示
      collection: true, //是否点赞
      isgive_ups: "", //点赞状态
      z_img: require("../assets/img/dianzan_icon.png"),
      z_img1: require("../assets/img/dianzan_icon1.png"),
      collection1: true, //是否收藏
      isCollections: "", //收藏状态
      isCollectionss:'',    //列表点赞状态
      c_img: require("../assets/img/shoucang_icon.png"),
      c_img1: require("../assets/img/shoucang_icon1.png"),
      C_Progress: {}, //投诉进展
      A_Progress: [],
      radio: "1",
      radio1: "0",
      opinion_ipt: "",
      page: 1, //当前页
      load: true,
      li_id:false,
    };
  },

  methods: {
    Go_HotBrands(id){
this.$router.push({
          name: "BrandHome",
          params: { id: id },
        });
    },
    // 二级点赞   昨天晚上写到这里了
    Eject_Commentss(is_like,id) {
      var that=this;
      // console.log(is_like);
      console.log(that.comment_lists)
      userComment_like({ comment_id: id}).then((res) => {
          // console.log(res);
          that.reload();
          that.$message.success(res.data.msg);
        });
    },
    Go_Login(){
      this.$confirm('您未登录,是否前往登录页面？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$router.push({ name: "login" });     //去登录
        }).catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '用户已取消'
          // });          
        });
    },
    // 投诉进度
    progress() {
      var that = this;
      if(!that.tokens){
      Axios({
          method: "get",
          url: "https://tsadmin.qudong.com/api/progress_one",
          params: {
            complaint_id: that.Xq_Id,
            key:md5('MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKB'),
          },
        }).then((res) => {
         //console.log(res);
        that.C_Progress = res.data.data;
        that.A_Progress =res.data.data.publish ;
        // //console.log(Object.values(that.A_Progress))
        });
      }else{
   Progress({ complaint_id: that.Xq_Id }).then((res) => {
        //console.log(res);
        that.C_Progress = res.data.data;
        that.A_Progress = res.data.data.publish.reverse();
      });
      }
    },

    G_login() {
      this.$router.push({ name: "login" });
    },

    Complaint_details() {
      let that = this;
      let tokens = localStorage.getItem("token");
      if (tokens) {
        //console.log(tokens)
        complaint_details({ complaint_id: that.Xq_Id, }).then((res) => {
          console.log(res.data);
          this.details_list = res.data.data;
          this.details_list_name = res.data.data.complaintBusiness;
        });
      } else {
        Axios({
          method: "get",
          url: "https://tsadmin.qudong.com/api/complaint_details_one",
          params: {
            complaint_id: that.Xq_Id,
            key:md5('MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKB'),
          },
        }).then((res) => {
          //console.log(res.data);
          this.details_list = res.data.data;
          this.details_list_name = res.data.data.complaintBusiness;
        });
      }
    },
    comment_list() {
      // let tokens = localStorage.getItem("token");
      let that = this;
      if (that.tokens) {
        // //console.log(that.Xq_Id);
        comment_list({ complaint_id: that.Xq_Id, }).then((res) => {
          // console.log(res.data.data.data);
          that.comment_lists = res.data.data.data;
      //     for(let i of that.comment_lists){
      //         console.log(i.id)
      //   userCommentLike_status({ comment_id: i.id }).then((res) => {
      //   console.log(res);
      // });
      //     }
        });
      }else{
        Axios({
          method: "get",
          url: "https://tsadmin.qudong.com/api/comment_list_one",
          params: {
            complaint_id: that.Xq_Id,
            key:md5('MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKB'),
          },
        }).then((res) => {
          console.log(res.data.data);
          that.comment_lists = res.data.data.data;
        });
      }
    },

    scroll() {
      var that=this;
      let isLoading = true; //是否有数据可以加载
      window.onscroll = async () => {
        // 距离底部200px时加载一次  
        let bottomOfWindow =document.documentElement.scrollHeight  -document.documentElement.clientHeight -document.documentElement.scrollTop <=0.25;
        //console.log(bottomOfWindow)
   
        if (bottomOfWindow && isLoading == true) {
          this.page = this.page + 1; //每次分页+1
           await Axios({
            method: "get",
            url: "https://tsadmin.qudong.com/api/comment_list_one",
            params: {
              complaint_id: that.Xq_Id,
              key:md5('MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKB'),
              page: this.page,
            },
          }).then((res) => {
            //console.log(res.data.data.data);
            if (res.data.data.data.length > 0) {
              this.comment_lists.push(...res.data.data.data); //追加数据 使用 ...语法
              isLoading = true;
              this.load = true;
            } else {
              this.load = false;
              isLoading = false; //无数据可以加载
            }
          });
        }
      };
    },


    // 去相关投诉
    Related_complaints(complaint_business_id) {
      let that = this;
      let tokens = localStorage.getItem("token");
      if (tokens) {
        this.$router.push({
          name: "RelatedComplaints",
          query: { id: that.Xq_Id, Obid: complaint_business_id },
        });
      } else {
        this.$confirm('您未登录,是否前往登录页面？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$router.push({ name: "login" });     //去登录
        }).catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '用户已取消'
          // });          
        });
      }
    },
    descInput() {
      //console.log(this.msg.length);
      this.remnant = this.msg.length;
    },
    descInputs() {
      //console.log(this.msgs.length);
      this.remnants = this.msgs.length;
    },
        descInputs1() {
      //console.log(this.msgs1.length);
      this.remnants1 = this.msgs1.length;
    },
    // 弹出回复
    Eject_Comments(index, id, userinfo) {
      //console.log(id);
      //console.log(index);
      //console.log(this.hf_cm);
      //console.log(userinfo);
      if (this.hf_cm == index) {
        this.hf_cm = -1;
      } else {
        this.hf_cm = index;
      }
    },
    

    // 二级弹出评论
        // 弹出回复
    Eject_Comments1(index, id, userinfo1,inde) {
      //console.log(id);
      //console.log(index);
      //console.log(this.hf_cm1);
      //console.log(userinfo1);
//       if(id==this.comment_lists[inde].childrenList[index].id){
// this.hf_cm1=!this.hf_cm1;
//       }
      
//       // this.comment_lists[inde].childrenList[index]=this.hf_cm1
//       //console.log(this.comment_lists[inde].childrenList[index].id)
      if (this.hf_cm1 == inde) {
        this.hf_cm1 = -1;
      } else {
        this.hf_cm1 = index;
      }
    },
    success(m) {
      layer.msg(m, { icon: 1 });
    },
    error(m) {
      layer.msg(m, { icon: 0 });
    },

    Reply() {
      var that = this;
      if(that.tokens){
        instantly_message({ complaint_id: that.Xq_Id, content: that.msg }).then(
        (res) => {
          //console.log(res);
          that.$message.success(res.data.msg);
          // 刷新当前页面
          that.reload();
        }
      );
      }else{
         this.$confirm('您未登录,是否前往登录页面？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$router.push({ name: "login" });     //去登录
        }).catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '用户已取消'
          // });          
        });
      }
      
    },
    // 点赞
    toCollect() {
      var that = this;
      let token = localStorage.getItem("token");
      if (token) {
        give_up({ complaint_id: that.Xq_Id }).then((res) => {
          //console.log(res);
          let msg = res.data.msg;
          if (res.data.code == 200) {
            that.$message.success(msg);
            that.details_list.like_num = that.details_list.like_num + 1;
          } else {
            that.$message.error(msg);
            that.details_list.like_num = that.details_list.like_num - 1;
          }
          that.collection = !that.collection;
        });
      } else {
        that.$message.error("请您登录以后点赞");
      }
    },
    // 点赞状态
    give_up1() {
      var that = this;
      if(that.tokens){
        give_ups({ complaint_id: that.Xq_Id, type: 1 }).then((res) => {
        //console.log(res.data.status);
        that.isgive_ups = res.data.status;
      });
      }
      
    },
    // 收藏
    toCollect1() {
      var that = this;
      let token = localStorage.getItem("token");
      if (token) {
        Collection({ complaint_id: that.Xq_Id }).then((res) => {
          //console.log(res);
          let msg = res.data.msg;
          if (res.data.code == 200) {
            that.$message.success(msg);
            that.details_list.collection_num = that.details_list.collection_num + 1;
          } else {
            that.$message.error(msg);
            that.details_list.collection_num = that.details_list.collection_num - 1;
          }
          that.collection1 = !that.collection1;
        });
      } else {
        that.$message.error("请您登录以后收藏");
      }
    },
    // 收藏状态
    Collections() {
      var that = this;
      if(that.tokens){
        give_ups({ complaint_id: that.Xq_Id, type: 2 }).then((res) => {
        //console.log(res.data.status);
        that.isCollections = res.data.status;
      });
      }
    },
    
    // 点击分享微博,QQ,QQ空间
    handleCommand(command) {
      if (command == "a") {
        let urls="https://tousu.qudong.com//#/Details?id="+this.Xq_Id
        window.open(
          // var shareUrl="http://***/?shareUrl=sharereg?couid=" + res.uid + "%26name=" + encodeURIComponent(res.name) ;
          "https://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=" +
            encodeURIComponent(urls)
             +
            "&title=" +
            this.details_list.statement_title +
            "&summary=" +
            this.details_list.complaint_details
        );
      } else if (command == "b") {
        let urls="https://tousu.qudong.com//#/Details?id="+this.Xq_Id
        window.open(
          "https://connect.qq.com/widget/shareqq/index.html?url=" +
            encodeURIComponent(urls)+
            "&title=" +
            this.details_list.statement_title +
            "&summary=" +
            this.details_list.complaint_details
        );
      } else if (command == "c") {
        let urls="https://tousu.qudong.com//#/Details?id="+this.Xq_Id
        window.open(
          "https://service.weibo.com/share/share.php?url=" +
            encodeURIComponent(urls)+
            "&title=" +
            this.details_list.statement_title +
            "&summary=" +
            this.details_list.complaint_details
        );
      }
    },
    opinion_send() {
      var that = this;
      //console.log(this.radio);
      //console.log(this.radio1);
      //console.log(this.opinion_ipt);
      //console.log(this.C_Progress.id);
      if (this.opinion_ipt == "") {
        that.$message.error("输入框不能为空");
      } else {
        Progress_reply({
          ce_id: this.C_Progress.id,
          is_approval: this.radio,
          is_open: this.radio1,
          content: this.opinion_ipt,
        }).then((res) => {
          //console.log(res);
          that.$message.success("发送成功");
          that.reload();
        });
      }
    },
    Hf_Comments(id) {
      var that = this;
      //console.log(id);
      //console.log(this.msgs);
      if(that.tokens){
        news_save({ pid: id, content: that.msgs }).then((res) => {
        //console.log(res.data.msg);
        that.$message.success(res.data.msg);
        that.reload();
      });
      }else{
        this.$confirm('您未登录,是否前往登录页面？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$router.push({ name: "login" });     //去登录
        }).catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '用户已取消'
          // });          
        });
      }
      
    },
        Hf_Comments1(id) {
      var that = this;
      //console.log(id);
      //console.log(this.msgs1);
      news_save({ pid: id, content: that.msgs1 }).then((res) => {
        //console.log(res.data.msg);
        that.$message.success(res.data.msg);
        that.reload();
      });
    },
  },
  mounted() {
    var that = this;
    let tokens = localStorage.getItem("token");
    let user_uid = localStorage.getItem("uid");
    that.user_uid = user_uid;
    that.tokens = tokens;
    // 接收传过来的Id
    that.Xq_Id = that.$route.query.id;
    that.Complaint_details();
    that.comment_list();
    that.give_up1(); //点赞状态
    that.Collections(); //收藏状态
    that.progress(); //投诉进度
    window.addEventListener('scroll', that.scroll, true)    //滚动加载评论列表
  },
};
</script>

<style scoped>
.details_list {
  width: 860px;
  background: #fff;
  padding: 20px;
  border-bottom: 1px solid #ccc;
}
.crumbs {
  margin-bottom: 20px;
}
.details_list_top {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.details_list_top_title {
  font-size: 19px;
  font-weight: 550;
}
.Related_complaints {
  color: #999999;
  cursor: pointer;
}
.details_list_content {
  width: 100%;
  display: flex;

  justify-content: space-between;
}
.details_list_content_left {
  width: 65%;
}
.details_list_content_left_ct {
  margin-bottom: 6px;
}
.details_list_content_right {
  width: 35%;
  height: 50px;
}
.details_list_content_right img {
  width: 131px;
  height: 116px;
  margin-left: 50px;
}
.details_list_bottom_box {
  display: flex;
  align-items: center;
}
.details_list_bottom_box img {
  width: 17px;
  height: 17px;
  margin-right: 3px;
  cursor: pointer;
}
.details_list_bottom_box div {
  margin-top: 3px;
  margin-right: 15px;
}
.details_list_bottom {
  display: flex;
}
.fenxiang img {
  width: 17px;
  margin-top: 5px;
  height: 17px;
}
/* 投诉详情 */
.Complaint_details {
  width: 860px;
  padding: 20px;
  background: #fff;
}
.Complaint_details_title {
  font-size: 19px;
  margin-bottom: 20px;
}
.Complaint_details_content {
  color: #333333;
}
/* 投诉进度 */
.Complaint_progress {
  margin-top: 20px;
  width: 860px;
  padding: 20px;
  background: #fff;
  border-bottom: 1px solid #ccc;
}
.Complaint_progress_title {
  font-size: 19px;
}
.Complaint_progress_bottom {
  width: 860px;
  padding: 20px;
  background: #fff;
}
.Complaint_progress_bottom_box {
  border-left: 1px solid #1f72d9;
  padding-left: 10px;
  position: relative;
  padding-top: 10px;
  padding-bottom: 20px;
}
.box_yuan {
  width: 8px;
  height: 8px;
  background: #1f72d9;
  border-radius: 50%;
  position: absolute;
  left: -5px;
  top: 15px;
}
.Complaint_progress_bottom_box_data {
  margin-bottom: 10px;
}

/* 评论 */
.comment {
  margin-top: 20px;
  width: 860px;
  padding: 20px;
  background: #fff;
  position: relative;
  border-bottom: 1px solid #ccc;
}
.comment_ipt {
  width: 860px;
  height: 35px;
  padding-left: 6px;
  border: 1px solid #ccc;
  border-radius: 8px;
}
.Enter_the_number {
  /* position:absolute;
    right:15px;
    top:30px; */
  margin-right: 20px;
  margin-top: 12px;
}
.Reply_box {
  width: 870px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.Reply {
  width: 95px;
  height: 30px;
  margin-top: 10px;
  cursor: pointer;
  background: #ccc;
  text-align: center;
  line-height: 30px;
  border-radius: 8px;
}
.Reply1 {
  width: 95px;
  height: 30px;
  margin-top: 10px;
  cursor: pointer;
  background: #1f72d9;
  text-align: center;
  line-height: 30px;
  border-radius: 8px;
  color: #fff;
}

/* 最新评论 */
.Latest_comments {
  width: 860px;
  padding: 20px;
  background: #fff;
}
.Latest_comments_title {
  font-size: 19px;
  font-weight: 550;
}
.Latest_comments_b {
  padding-bottom: 20px;
  border-bottom: 1px solid #ccc;
}
.Latest_comments_list {
  width: 860px;
  margin-top: 15px;
  display: flex;
}
.Latest_comments_list_left {
  width: 80px;
}
.Latest_comments_list_left img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 30px;
}
.Latest_comments_list_content {
  width: 600px;
}
.Latest_comments_list_content_top {
  display: flex;
  align-items: center;
}
.Latest_comments_list_content_top .names {
  font-size: 16px;
  margin-right: 10px;
}
.Latest_comments_list_content_top .datas {
  font-size: 14px;
}
.Latest_comments_list_content_bottom {
  margin-top: 10px;
}
.Latest_comments_list_right {
  display: flex;
  width: 185px;
  justify-content: flex-end;
}
.Latest_comments_list_right img {
  width: 18px;
  height: 18px;
  cursor: pointer;
}
.Latest_comments_list_right img:nth-child(1) {
  margin-right: 30px;
}
.Latest_comments_list_right img:nth-child(2) {
  margin-right: 8px;
}

/* 回复评论 */
.hf_comment {
  margin-top: 20px;
  width: 860px;
  background: #fff;
  position: relative;
}
.hf_comment_ipt {
  width: 860px;
  height: 35px;
  padding-left: 6px;
  border: 1px solid #ccc;
  border-radius: 8px;
}
.hf_Enter_the_number {
  /* position:absolute;
    right:15px;
    top:30px; */
  text-align: right;
  margin-top: 12px;
  margin-right: 20px;
}
.hf_Reply_box {
  width: 870px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.hf_Reply {
  width: 95px;
  height: 30px;
  margin-top: 10px;
  cursor: pointer;
  background: #ccc;
  text-align: center;
  line-height: 30px;
  border-radius: 8px;
}
.hf_Reply1 {
  width: 95px;
  height: 30px;
  margin-top: 10px;
  cursor: pointer;
  background: #1f72d9;
  text-align: center;
  line-height: 30px;
  border-radius: 8px;
  color: #fff;
}
.login_zs {
  margin-top: 30px;
  font-size: 22px;
  text-align: center;
  cursor: pointer;
}
/* 补充的投诉进展 */
.Complaint_progress_bottom_box_xing {
  display: flex;
}
.Complaint_progress_bottom_box_xing img {
  width: 128px;
  height: 18px;
}
.Complaint_progress_bottom_box_opinion {
  display: flex;
  align-items: center;
}
.opinion_send_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.opinion_send_ipt {
  width: 800px;
  height: 40px;
  border: 1px solid #999999;
  border-radius: 5px;
  margin: 20px 0;
  padding-left: 15px;
}
.opinion_send {
  width: 96px;
  height: 30px;
  background: #1f72d9;
  border-radius: 5px;
  text-align: center;
  line-height: 30px;
  color: #fff;
  margin-right: 30px;
  cursor: pointer;
}

/* 二级评论 */
.two_Latest_comments {
  width: 750px;
  padding: 20px;
  background: #f3f3f3;
  border-radius: 5px;
  margin-top: 20px;
  margin-left: 70px;
}
</style>