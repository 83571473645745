<template>
  <div class="BJS">
    <Header></Header>
        <!-- 左右两侧 -->
    <div class="Bottom_list">
      <div class="Bottom_list_bx">
        <DetailsLeft></DetailsLeft>
        <MainRight></MainRight>
      </div>
    </div>
       <!-- 底部 -->
    <Footer></Footer>
  </div>
</template>

<script>
// import { complaint_details } from "../request/api";
import Header from "../components/Header.vue";
import DetailsLeft from "../components/DeatilsLeft.vue";
import MainRight from "../components/MainRight.vue";
import Footer from "../components/Footer.vue";
export default {
  name: "Details",
  data() {
    return {
      Xq_Id: "", //上个页面传过来的id
    };
  },
  components: {
    Header,
   DetailsLeft,
    MainRight,
    Footer,
  },
  created() {
    var that = this;
    // 接收传过来的Id
    that.Xq_Id = that.$route.query.id;

  },
  methods: {
  },
};
</script>

<style scoped>
.BJS {
  position: relative;
  height: 100%;
  width: 100%;
  background-color: #f5f5f5;
}

.Bottom_list {
  width: 100%;
}
.Bottom_list_bx {
  width: 1200px;
  margin: 20px auto;
  display: flex;
  justify-content: space-between;
  /* background: red; */
}

</style>